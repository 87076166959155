import React from 'react';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import Filter from 'components/Filter';
import PostList from 'blocks/PostList';
import Message from 'components/Message';
import Layout from 'components/Layout';
import { DFPManager } from 'react-dfp';
import { FooterAd } from 'components/FooterAd';
class InfoPage extends BlockPage {
  state: any;
  props: any;
  componentDidMount() {
    setTimeout(() => {
      DFPManager.load();
    }, 5000);
  }
  constructor(props) {
    super(props);

    const { content } = props;
    this.state = {
      inputValue: null,
      postBlocks: content.infoPages.pages || [],
    };
  }

  handleFilter(e) {
    const { content } = this.props;
    const { value } = e.target;
    const { infoPages = [] } = content;

    const postBlocks = infoPages.pages.map(({ name, pages }) => ({
      name,
      pages: pages.filter(({ title }) =>
        [name, title].some((text: string) => (text ? text.toLowerCase().includes(value.toLowerCase()) : false)),
      ),
    }));

    this.setState({ inputValue: value, postBlocks });
  }

  renderChildren() {
    const { content } = this.props;
    const { postBlocks } = this.state;
    const { title, infoPages = [], adCode, adImage } = content;

    const results = postBlocks.reduce((acc, { pages }) => (acc += pages.length), 0);

    return (
      <>
        <BlockSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()} color="lime">
          <Filter.TextInputHeader
            title={title}
            placeholder="Type here to search"
            value={this.state.inputValue}
            onChange={(e) => this.handleFilter(e)}
          />
        </BlockSection>
        <BlockSection isFirstSection={false} pageHasAnnouncement={this.pageHasAnnouncement()}>
        <Layout.PageContainer hasSidebar={false}>
          <Layout.Content>
            <Filter.Content>
              {results ? (
                postBlocks.map(({ title, pages }, index) => (pages.length > 0) && <BlockSection><PostList key={`post-${index}`} title={title} items={pages} /> </BlockSection>)
              ) : (
                  <Message value={`Sorry there are no results for "${this.state.inputValue}"`} />
                )}
                <BlockSection style={{ textAlign: 'center'}}>
                      <FooterAd />
                    </BlockSection>
            </Filter.Content>
          </Layout.Content>
              </Layout.PageContainer>
        </BlockSection>
      </>
    );
  }
}

export default InfoPage;
